












import getCraftPages from '~/queries/craft-pages.gql'
import pageMixin from '@bkwld/cloak/mixins/page'
import Newsletter from '~/components/blocks/newsletter'
import {
	makeJsonLdOrganizationTag
	makeJsonLdSearchTag
} from '@bkwld/json-ld-formatters'
import brandLogo from 'library/assets/images/logo.svg'
export default
	name: 'Tower'

	mixins: [ pageMixin ]

	components: { Newsletter }

	head: ->
		# Store head in case we need to mutate it
		head = {
			...@buildHead()
			...(unless @$route.path == '/' then {} else
				script: [
					makeJsonLdOrganizationTag logo: process.env.URL + brandLogo
					makeJsonLdSearchTag urlTemplate: '/search?query={query}'
				]
			)
		}

		# Need to add a meta tag for only the home page. buildHead() sets meta tags
		# and we need to preserve those and add to it
		if @page.uri == '__home__'
			head.meta = [
				...head.meta
				{
					name: 'google-site-verification'
					content: '9AjH2Tl0Sv3_XtIW58BqdJNZRAs3PM5Gb0kVF-gYWM4'
				}
			]

		return head

	# Get Tower data
	asyncData: ({ app, route, payload }) ->

		# Get data
		[ page ] = payload || await app.$craft.getEntries
			query: getCraftPages
			variables:
				section: 'towers'
				type: 'towers'
				uri: route.path?.substr(1) || '__home__'
		return app.$notFound() unless page

		# Set data
		return { page }

	mounted: ->
		if @$route.query['cart']
			@$store.commit('cart/open')

			newUrl = window.location.href
			newUrl = newUrl.split '?'

			if newUrl?[1]?

				if newUrl?[1]?.includes '&'
					newUrl[1] = newUrl[1].replace('cart=true&', '')

					# build the new URL and set it
					newUrl = newUrl.join().replace(',', '?')
					history.replaceState {}, null, newUrl


				else
					# just use the [0]
					history.replaceState {}, null, newUrl[0]

			else
				# just use the [0]
				history.replaceState {}, null, newUrl[0]



	computed:

		newsletterData: -> @$store.state.globals.newsletter

		newsletterBlock: ->
			body: [
				"<h2 class='style-h2'>#{@newsletterData.title}</h2>" if @newsletterData.title
				"<p>#{@newsletterData.description}</p>" if @newsletterData.description
			].join ' '

