


















export default
	inject:
		onPDP:
			default: false

	props: block: Object

	computed:
		newsletter: -> @$store.state.globals.newsletter
		newsletterTitle: -> @newsletter.title
		newsletterDescription: -> @newsletter.description


